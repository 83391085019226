//#region IMPORT

import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_NAVIGATIONMENU_BRINS } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { WebAddressConstant } from "src/app/constants/webaddress.constant";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	public _enumNavigationMenu= ENUM_NAVIGATIONMENU_BRINS;

	public _booleanMenuMaximize: boolean;
	public _booleanMenuBarMinimize: boolean;
	public _booleanChatMelisa: boolean;

	public _stringConversation: string;
	public _stringConstant = StringConstant;

	public _numberPixelHeight: number;
	public _numberPixelWidth: number;

	public _arrayStringConversation: Array<string>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._booleanMenuMaximize = true;
		this._booleanMenuBarMinimize = false;
		this._booleanChatMelisa = false;

		this._numberPixelHeight = 0;
		this._numberPixelWidth = 0;

		this._stringConversation = "";
		this._arrayStringConversation = [];
	}

	//#endregion


	//#region LISTENER

	@HostListener("window:resize", ["$event"])
	// eslint-disable-next-line no-unused-vars
	getScreenSize(event?): void
	{
		this._numberPixelHeight = window.innerHeight;
		this._numberPixelWidth = window.innerWidth;

		if(this._numberPixelWidth <= 800)
		{
			this._booleanMenuMaximize = false;
		}
		else
		{
			this._booleanMenuMaximize = true;
		}
	}

	//#endregion


	//#region NGONINIT

	ngOnInit(): void
	{
		this.getScreenSize();
	}

	//#endregion


	//#region NAVIGATION

	public goToAbout(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "about"]);
	}

	public goToProductList(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "product", "list"]);
	}

	public goToMobileApplication(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "mobileapplication"]);
	}

	public goToBRINSApi(): void
	{
		this.minimizeMenu(false);
		window.open(this._stringConstant.STRING_WEBSITE_BRINS_BRINSAMAN, "_blank");
	}

	public goToPromo(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "promo"]);
	}

	public goToContact(): void
	{
		this.minimizeMenu(false);
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB + "/home/about/contactandlocation", "_blank");
	}

	public goToHome(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["/"]);
	}

	public goToBRINSDIGITAL(): void
	{
		this.minimizeMenu(false);
		window.open(this._stringConstant.STRING_WEBSITE_BRINS_BRINSDIGITAL, "_blank");
	}

	public goToPromotion(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "promotion"]);
	}

	public goToPartner(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "partner"]);
	}

	public goToMainDomain(): void
	{
		this.minimizeMenu(false);
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB, "_blank");
	}

	//#endregion


	//#region SCROLL

	public scrolled(event: any): void
	{
		const top = document.getElementById("divMainDashboard");

		if (top !== null)
		{
			if(event.srcElement.scrollTop <= 200)
			{
				this._booleanMenuMaximize = true;
			}
			else
			{
				this._booleanMenuMaximize = false;
			}
		}

		this._booleanChatMelisa = false;
	}

	public minimizeMenu(booleanMenu: boolean): void
	{
		this._booleanMenuBarMinimize = booleanMenu;
	}

	//#endregion

	//#region FUNCTION

	public setBooleanChatMelisa(booleanMenu: boolean): void
	{
		this._booleanChatMelisa = booleanMenu;
	}

	public setConvertionArray(): void
	{
		if(this._stringConversation === null || this._stringConversation === undefined || this._stringConversation === "")
		{

		}
		else
		{
			this._arrayStringConversation.push(this._stringConversation);
			this._stringConversation = "";
		}
	}

	public setActiveNavigation(enumAboutBRINS: ENUM_NAVIGATIONMENU_BRINS): boolean
	{
		if(enumAboutBRINS === this._enumNavigationMenu.Home)
		{
			if(this._router.url === "/home")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Product)
		{
			if(this._router.url === "/home/product/list")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.MobileApplication)
		{
			if(this._router.url === "/home/mobileapplication")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.BRINSAPI)
		{
			if(this._router.url === "/home/brinsupdate" || this._router.url === "/home/brinsupdate/newsfeeds" || this._router.url === "/home/brinsupdate/community" || this._router.url === "/home/brinsupdate/article" || this._router.url === "/home/brinsupdate/testimony" || this._router.url === "/home/brinsupdate/financialliteracy")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Partner)
		{
			if(this._router.url === "/home/partner")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Promo)
		{
			if(this._router.url === "/home/promo")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Contact)
		{
			if(this._router.url === "/home/contact")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	//#endregion

}

//#endregion
