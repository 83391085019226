//#region IMPORT

import { Component } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ENUM_PERIOD_TYPE } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { ContentModel } from "src/app/models/content.model";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-landing",
		templateUrl: "./landing.component.html",
		styleUrls: ["./landing.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class LandingComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringImageMainHeader: string;
	public _stringImageContentRumahku: string;
	public _stringImageContentKTU: string;
	public _stringConstant = StringConstant;

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();
	public _arraySocialMedia: Array<ContentModel>;
	public _enumPeriodeProduct = ENUM_PERIOD_TYPE;
	public _booleanModalVideo: boolean;
	public _stringURLAboutBRINS: string;
	public _stringURLAboutBRINSEmbed: SafeResourceUrl;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._booleanModalVideo = false;
		this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/micro/header_landing_mikro.jpg");
		this._stringImageContentRumahku = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/micro/photo_product_rumahku.jpg");
		this._stringImageContentKTU = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/micro/photo_product_ktu.jpg");
		this._stringURLAboutBRINS = "https://www.youtube.com/embed/dGtDlnMttH8";
		this._stringURLAboutBRINSEmbed = "https://www.youtube.com/embed/dGtDlnMttH8";

		this._arraySocialMedia =
		[
			{
				Name: "Instagram",
				Path: "../../../assets/logos/sakina/logo_instagram.svg",
				Link: "https://www.instagram.com/brinsurtech"
			},
			{
				Name: "Facebook",
				Path: "../../../assets/logos/sakina/logo_facebook.svg",
				Link: "https://www.facebook.com/brinsurtech"
			},
			{
				Name: "Youtube",
				Path: "../../../assets/logos/sakina/logo_youtube.svg",
				Link: "https://www.youtube.com/channel/UCdwtS_j2DKMiVSDaD2SCLwQ/featured"
			},
			{
				Name: "Twitter",
				Path: "../../../assets/logos/sakina/logo_twitter.svg",
				Link: "https://twitter.com/brins_insurance"
			},
			{
				Name: "TikTok",
				Path: "../../../assets/logos/sakina/logo_tiktok.svg",
				Link: "https://www.tiktok.com/@brinsurtech"
			},
			{
				Name: "LinkedIn",
				Path: "../../../assets/logos/sakina/logo_linkedin.svg",
				Link: "https://id.linkedin.com/company/pt-bri-asuransi-indonesia"
			},
		];
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	public setBooleanVideo(booleanModalVideo: boolean): void
	{
		this._booleanModalVideo = booleanModalVideo;
		if(this._booleanModalVideo)
		{
			this._functionUserInterface.showCurtainLoading();
			this._stringURLAboutBRINSEmbed = this._stringURLAboutBRINS+this._stringConstant.STRING_IFRAME_EMBEDVIDEO_AUTOPLAY;
		}
		else
		{
			this._functionUserInterface.hideCurtainLoading();
			this._stringURLAboutBRINSEmbed = this._stringURLAboutBRINS+this._stringConstant.STRING_IFRAME_EMBEDVIDEO_PAUSE;
		}
	}

	getImageMain(): any
	{
		if(this._stringImageMainHeader === null && this._stringImageMainHeader === undefined && this._stringImageMainHeader === "")
		{
			return {};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	getImageRumahku(): any
	{
		if(this._stringImageContentRumahku === null && this._stringImageContentRumahku === undefined && this._stringImageContentRumahku === "")
		{
			return {};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageContentRumahku + ")"};
		}
	}

	getImageKTU(): any
	{
		if(this._stringImageContentKTU === null && this._stringImageContentKTU === undefined && this._stringImageContentKTU === "")
		{
			return {};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageContentKTU + ")"};
		}
	}

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	//#endregion


	//#region NAVIGATION

	public goToDetail(stringToken: string): void
	{
		this._router.navigate(["home", "product", "detail", stringToken]);
	}

	goToSignIn(): void
	{
		this._router.navigate(["signin"]);
	}

	goToSNS(stringURL: string): void
	{
		window.open(stringURL);
	}

	goToProductAsmik(stringToken: string): void
	{
		if(stringToken === StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
		{
			this._router.navigate(["home", "productasmik", "damageofbusinessplace", "yearly"]);
		}
		else if(stringToken === StringConstant.STRING_VALUE_PRODUCT_MYHOUSE)
		{
			this._router.navigate(["home", "productasmik", "myhouse", "yearly"]);
		}
		else if(stringToken === StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
		{
			this._router.navigate(["home", "productasmik", "mymotorcycle", "yearly"]);
		}
		else
		{

		}
	}

	//#endregion

}
