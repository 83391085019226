<div class="DivPageContainer" id="divMainLandingContainer">
	<section class="SectionMain" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormHorizontalContainer" id="divMainLanding">
			<div class="DivForm2Column DivHeaderCenterContainer">
				<div class="DivHeaderContentContainer">
					<h1 class="FontSoftColor">Bisnis</h1>
					<h1 class="FontSoftColor">Mikro</h1>
					<p class="FontSoftColor">Produk Asuransi Mikro hadir sebagai bentuk komitmen BRINS dalam melindungi dan menjangkau seluruh lapisan masyarakat Indonesia.</p>
				</div>
				<!-- <button class="ButtonSubmit" (click)="goToSignIn();"><label class="FontSoftColor">Login</label></button> -->
			</div>

			<div class="DivForm2Column DivSocialMediaCenterContainer">
				<div class="DivSocialMedia">
					<img [src]="socialMedia.Path" *ngFor="let socialMedia of _arraySocialMedia" (click)="goToSNS(socialMedia.Link!)">
				</div>
			</div>
		</div>
	</section>

	<section class="SectionSecondary">
		<div class="DivFormVerticalContainer DivSiteContainer" id="divSiteLandingContainer">
			<h1 class="Bold">Produk Unggulan</h1>

			<div class="DivHeaderProductLanding">
				<div class="DivHeaderProductDescription">
					<h2>Asuransi Mikro Rumahku</h2>

					<div class="DivProductContent">
						<p>Memberikan proteksi terhadap bangunan tempat tinggal tertanggung atau tempat tinggal merangkap tempat usaha rusak akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis.</p>
						<button class="ButtonSubmit" (click)="goToDetail('0')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
					</div>
				</div>

				<div class="DivHeaderProductImageDescription" [ngStyle]="getImageRumahku()">
					<img src="../../../assets/gifs/transparent.gif">
				</div>
			</div>

			<div class="DivFormHorizontalContainer AlignStretch">
				<div class="DivFooterProductLanding BorderBottomRight35 BorderBottomLeft35">
					<div class="DivProductImageDescription BorderBottomLeft35" [ngStyle]="getImageKTU()">
						<img src="../../../assets/gifs/transparent.gif">
					</div>

					<div class="DivProductDescription">
						<h2>Asuransi Mikro KTU</h2>

						<div class="DivProductContent">
							<p>Memberikan santunan berupa uang apabila peserta asuransi mengalami kerusakan tempat usaha akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis.</p>
							<button class="ButtonSubmit" (click)="goToDetail('1')"><label class="FontSoftColor">Selengkapnya &#x2192;</label></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>