//#region IMPORT

import { Component, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ContentModel } from "src/app/models/content.model";
import { SessionService } from "src/app/services/session.service";
import MikroPartner from "src/assets/data/mikropartner.json";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-partner",
		templateUrl: "./partner.component.html",
		styleUrls: ["./partner.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class PartnerComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringConstant = StringConstant;
	public _numberConstant = NumberConstant;

	public _stringImageMainHeader: string;

	public _numberIndexPartner: number;
	public _numberIndexPartnerCurrent: number;

	public _arrayModelMikroPartner: Array<ContentModel>;

	public _intervalIDSection1: any;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _elementRef: ElementRef)
	{
		super(serviceSession, router);
		this._stringImageMainHeader = this._pipeConvertToBroadcastURL.transform("../../Website/assets/background/sakina/micro/header_mitra_mikro.jpg");
		this._numberIndexPartner = 2;
		this._numberIndexPartnerCurrent = 4;
		this._arrayModelMikroPartner = MikroPartner;
		this.setIntervalPartner();
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region ONDESTROY

	ngOnDestroy(): void
	{
		clearInterval(this._intervalIDSection1);
	}

	//#endregion


	//#region FUNCTION

	showContentNext(): void
	{
		this._numberIndexPartnerCurrent += NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		if (this._numberIndexPartnerCurrent > this._arrayModelMikroPartner.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE)
		{
			this._numberIndexPartnerCurrent = 0;
		}
		else
		{

		}

		const stringHTML = "<label for=\"inputImageGallery"+ this._numberIndexPartnerCurrent +"\" id=\"labelImageGallery"+ this._numberIndexPartnerCurrent +"\" class=\"LabelImageNonActive LabelPreAnimation\"><img src=\""+ this._arrayModelMikroPartner[this._numberIndexPartnerCurrent].Path +"\" alt=\"\"><p>"+ this._arrayModelMikroPartner[this._numberIndexPartnerCurrent].Title +"</p></label>";

		const elementLabelNonActive = this._elementRef.nativeElement.querySelectorAll("label.LabelImageNonActive");
		const elementSectionLabelContainer = this._elementRef.nativeElement.querySelector("#sectionImageGallery");

		elementSectionLabelContainer.insertAdjacentHTML("beforeend", stringHTML);

		setTimeout(function()
		{
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let numberIndex = 0; numberIndex < elementLabelNonActive.length; numberIndex++)
			{
				elementLabelNonActive[numberIndex].classList.remove("LabelPreAnimation");
			}
		},200);

		elementSectionLabelContainer.removeChild(elementSectionLabelContainer.firstElementChild);
		elementLabelNonActive[2].classList.remove("LabelImageActive");
		elementLabelNonActive[3].classList.add("LabelImageActive");

		// elementLabelNonActive[this._numberIndexPartner].scrollIntoView({behavior: "smooth", inline: "center", block: "center"});
	}

	showContentPrevious(): void
	{
		this._numberIndexPartnerCurrent -= NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		if (this._numberIndexPartnerCurrent < 0)
		{
			this._numberIndexPartnerCurrent = this._arrayModelMikroPartner.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE;
		}
		else
		{

		}

		const stringHTML = "<label for=\"inputImageGallery"+ this._numberIndexPartnerCurrent +"\" id=\"labelImageGallery"+ this._numberIndexPartnerCurrent +"\" class=\"LabelImageNonActive\"><img src=\""+ this._arrayModelMikroPartner[this._numberIndexPartnerCurrent].Path +"\" alt=\"\"><p>"+ this._arrayModelMikroPartner[this._numberIndexPartnerCurrent].Title +"</p></label>";

		const elementLabelNonActive = this._elementRef.nativeElement.querySelectorAll("label.LabelImageNonActive");
		const elementSectionLabelContainer = this._elementRef.nativeElement.querySelector("#sectionImageGallery");

		elementSectionLabelContainer.insertAdjacentHTML("afterbegin", stringHTML);
		elementSectionLabelContainer.removeChild(elementSectionLabelContainer.lastElementChild);
		elementLabelNonActive[2].classList.remove("LabelImageActive");
		elementLabelNonActive[1].classList.add("LabelImageActive");

		// elementLabelNonActive[this._numberIndexPartner].scrollIntoView({behavior: "smooth", inline: "center", block: "center"});
	}

	getImageMain(): any
	{
		if(this._stringImageMainHeader === null && this._stringImageMainHeader === undefined && this._stringImageMainHeader === "")
		{
			return {};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}

	setIntervalPartner(): void
	{
		this._intervalIDSection1 = setInterval(() => { this.showContentNext(); }, this._numberConstant.NUMBER_INTERVAL_TWO_SECONDS);
	}

	//#endregion


	//#region WEB SERVICES


	//#endregion


	//#region NAVIGATION

	//#endregion

}
